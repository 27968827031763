/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // Dashboard
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:rectangle-group',
        link : 'dashboard',
    },

    // Customers
    {
        id   : 'customers',
        title: 'Customers',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : 'customers',
    },

    // Drivers
    {
        id   : 'drivers',
        title: 'Drivers',
        type : 'basic',
        icon : 'custom:delivery',
        link : 'drivers',
    },

    // Jobs
    {
        id   : 'jobs',
        title: 'Jobs',
        type : 'basic',
        icon : 'heroicons_outline:clipboard',
        link : 'jobs',
    },

    // Wages
    {
        id   : 'wages',
        title: 'Wages',
        type : 'basic',
        icon : 'custom:money',
        link : 'wages',
    },
    
    // Invoices
    {
        id   : 'invoices',
        title: 'Invoices',
        type : 'basic',
        icon : 'heroicons_outline:document-chart-bar',
        link : 'invoices',
    },

    //Daily Job Sheet
    {
        id   : 'dailyJobSheet',
        title: 'Daily Job Sheet',
        type : 'basic',
        icon : 'custom:calendar',
        link : 'dailyjobsheet',
    },

    // Reporting
    {
        id   : 'reporting',
        title: 'Reporting',
        type : 'basic',
        icon : 'heroicons_outline:chart-bar',
        link : 'reporting',
    },

    // Settings
    {
        id      : 'settings',
        title   : 'Settings',
        type    : 'basic',
        icon    : 'heroicons_outline:cog-8-tooth',
        link    : 'settings',
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        tooltip : 'Dashboard',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
