/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CurrencyType } from '../models/currency-type';
import { DateTimeInvoiceDtoIGroupingListBaseResponse } from '../models/date-time-invoice-dto-i-grouping-list-base-response';
import { InvoiceBuilderProjectDtoListBaseResponse } from '../models/invoice-builder-project-dto-list-base-response';
import { InvoiceDto } from '../models/invoice-dto';
import { InvoiceDtoBaseResponse } from '../models/invoice-dto-base-response';
import { InvoiceDtoListBaseResponse } from '../models/invoice-dto-list-base-response';
import { InvoiceItemDtoListBaseResponse } from '../models/invoice-item-dto-list-base-response';
import { InvoiceListDtoPagedResponseBaseResponse } from '../models/invoice-list-dto-paged-response-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiInvoiceGetAllGet
   */
  static readonly ApiInvoiceGetAllGetPath = '/api/Invoice/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<InvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetAllGet$Plain(params?: {
  }): Observable<InvoiceDtoListBaseResponse> {

    return this.apiInvoiceGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoListBaseResponse>) => r.body as InvoiceDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<InvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetAllGet$Json(params?: {
  }): Observable<InvoiceDtoListBaseResponse> {

    return this.apiInvoiceGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoListBaseResponse>) => r.body as InvoiceDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceGetForCompanyGet
   */
  static readonly ApiInvoiceGetForCompanyGetPath = '/api/Invoice/get-for-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetForCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetForCompanyGet$Plain$Response(params?: {
    companyId?: number;
  }): Observable<StrictHttpResponse<InvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetForCompanyGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetForCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetForCompanyGet$Plain(params?: {
    companyId?: number;
  }): Observable<InvoiceDtoListBaseResponse> {

    return this.apiInvoiceGetForCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoListBaseResponse>) => r.body as InvoiceDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetForCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetForCompanyGet$Json$Response(params?: {
    companyId?: number;
  }): Observable<StrictHttpResponse<InvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetForCompanyGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetForCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetForCompanyGet$Json(params?: {
    companyId?: number;
  }): Observable<InvoiceDtoListBaseResponse> {

    return this.apiInvoiceGetForCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoListBaseResponse>) => r.body as InvoiceDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoicePaginatedListGet
   */
  static readonly ApiInvoicePaginatedListGetPath = '/api/Invoice/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoicePaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicePaginatedListGet$Plain$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<InvoiceListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoicePaginatedListGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoicePaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicePaginatedListGet$Plain(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<InvoiceListDtoPagedResponseBaseResponse> {

    return this.apiInvoicePaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceListDtoPagedResponseBaseResponse>) => r.body as InvoiceListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoicePaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicePaginatedListGet$Json$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<InvoiceListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoicePaginatedListGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoicePaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicePaginatedListGet$Json(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<InvoiceListDtoPagedResponseBaseResponse> {

    return this.apiInvoicePaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceListDtoPagedResponseBaseResponse>) => r.body as InvoiceListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceGetInvoicePost
   */
  static readonly ApiInvoiceGetInvoicePostPath = '/api/Invoice/get-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetInvoicePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetInvoicePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<InvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetInvoicePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetInvoicePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetInvoicePost$Plain(params?: {
    id?: number;
  }): Observable<InvoiceDtoBaseResponse> {

    return this.apiInvoiceGetInvoicePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoBaseResponse>) => r.body as InvoiceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetInvoicePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetInvoicePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<InvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetInvoicePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetInvoicePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetInvoicePost$Json(params?: {
    id?: number;
  }): Observable<InvoiceDtoBaseResponse> {

    return this.apiInvoiceGetInvoicePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoBaseResponse>) => r.body as InvoiceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceAddInvoicePost
   */
  static readonly ApiInvoiceAddInvoicePostPath = '/api/Invoice/add-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceAddInvoicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceAddInvoicePost$Plain$Response(params?: {
    body?: InvoiceDto
  }): Observable<StrictHttpResponse<InvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceAddInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceAddInvoicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceAddInvoicePost$Plain(params?: {
    body?: InvoiceDto
  }): Observable<InvoiceDtoBaseResponse> {

    return this.apiInvoiceAddInvoicePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoBaseResponse>) => r.body as InvoiceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceAddInvoicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceAddInvoicePost$Json$Response(params?: {
    body?: InvoiceDto
  }): Observable<StrictHttpResponse<InvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceAddInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceAddInvoicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceAddInvoicePost$Json(params?: {
    body?: InvoiceDto
  }): Observable<InvoiceDtoBaseResponse> {

    return this.apiInvoiceAddInvoicePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoBaseResponse>) => r.body as InvoiceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceSendInvoiceEmailPost
   */
  static readonly ApiInvoiceSendInvoiceEmailPostPath = '/api/Invoice/send-invoice-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceSendInvoiceEmailPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiInvoiceSendInvoiceEmailPost$Plain$Response(params?: {
    invoiceId?: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceSendInvoiceEmailPostPath, 'post');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceSendInvoiceEmailPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiInvoiceSendInvoiceEmailPost$Plain(params?: {
    invoiceId?: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanBaseResponse> {

    return this.apiInvoiceSendInvoiceEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceSendInvoiceEmailPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiInvoiceSendInvoiceEmailPost$Json$Response(params?: {
    invoiceId?: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceSendInvoiceEmailPostPath, 'post');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceSendInvoiceEmailPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiInvoiceSendInvoiceEmailPost$Json(params?: {
    invoiceId?: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanBaseResponse> {

    return this.apiInvoiceSendInvoiceEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceUpdateInvoicePost
   */
  static readonly ApiInvoiceUpdateInvoicePostPath = '/api/Invoice/update-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceUpdateInvoicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceUpdateInvoicePost$Plain$Response(params?: {
    body?: InvoiceDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceUpdateInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceUpdateInvoicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceUpdateInvoicePost$Plain(params?: {
    body?: InvoiceDto
  }): Observable<BooleanBaseResponse> {

    return this.apiInvoiceUpdateInvoicePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceUpdateInvoicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceUpdateInvoicePost$Json$Response(params?: {
    body?: InvoiceDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceUpdateInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceUpdateInvoicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvoiceUpdateInvoicePost$Json(params?: {
    body?: InvoiceDto
  }): Observable<BooleanBaseResponse> {

    return this.apiInvoiceUpdateInvoicePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceToggleArchiveInvoicePost
   */
  static readonly ApiInvoiceToggleArchiveInvoicePostPath = '/api/Invoice/toggle-archive-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceToggleArchiveInvoicePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceToggleArchiveInvoicePost$Plain$Response(params?: {
    invoiceId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceToggleArchiveInvoicePostPath, 'post');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceToggleArchiveInvoicePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceToggleArchiveInvoicePost$Plain(params?: {
    invoiceId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiInvoiceToggleArchiveInvoicePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceToggleArchiveInvoicePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceToggleArchiveInvoicePost$Json$Response(params?: {
    invoiceId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceToggleArchiveInvoicePostPath, 'post');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceToggleArchiveInvoicePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceToggleArchiveInvoicePost$Json(params?: {
    invoiceId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiInvoiceToggleArchiveInvoicePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceGetItemsForInvoiceGet
   */
  static readonly ApiInvoiceGetItemsForInvoiceGetPath = '/api/Invoice/get-items-for-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetItemsForInvoiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetItemsForInvoiceGet$Plain$Response(params?: {
    invoiceId?: number;
  }): Observable<StrictHttpResponse<InvoiceItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetItemsForInvoiceGetPath, 'get');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetItemsForInvoiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetItemsForInvoiceGet$Plain(params?: {
    invoiceId?: number;
  }): Observable<InvoiceItemDtoListBaseResponse> {

    return this.apiInvoiceGetItemsForInvoiceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceItemDtoListBaseResponse>) => r.body as InvoiceItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetItemsForInvoiceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetItemsForInvoiceGet$Json$Response(params?: {
    invoiceId?: number;
  }): Observable<StrictHttpResponse<InvoiceItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetItemsForInvoiceGetPath, 'get');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetItemsForInvoiceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetItemsForInvoiceGet$Json(params?: {
    invoiceId?: number;
  }): Observable<InvoiceItemDtoListBaseResponse> {

    return this.apiInvoiceGetItemsForInvoiceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceItemDtoListBaseResponse>) => r.body as InvoiceItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceGetProjectsForInvoiceGet
   */
  static readonly ApiInvoiceGetProjectsForInvoiceGetPath = '/api/Invoice/get-projects-for-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetProjectsForInvoiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetProjectsForInvoiceGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<InvoiceBuilderProjectDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetProjectsForInvoiceGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceBuilderProjectDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetProjectsForInvoiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetProjectsForInvoiceGet$Plain(params?: {
  }): Observable<InvoiceBuilderProjectDtoListBaseResponse> {

    return this.apiInvoiceGetProjectsForInvoiceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceBuilderProjectDtoListBaseResponse>) => r.body as InvoiceBuilderProjectDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetProjectsForInvoiceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetProjectsForInvoiceGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<InvoiceBuilderProjectDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetProjectsForInvoiceGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceBuilderProjectDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetProjectsForInvoiceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetProjectsForInvoiceGet$Json(params?: {
  }): Observable<InvoiceBuilderProjectDtoListBaseResponse> {

    return this.apiInvoiceGetProjectsForInvoiceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceBuilderProjectDtoListBaseResponse>) => r.body as InvoiceBuilderProjectDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiInvoiceGetUpcomingGet
   */
  static readonly ApiInvoiceGetUpcomingGetPath = '/api/Invoice/get-upcoming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetUpcomingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetUpcomingGet$Plain$Response(params?: {

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetUpcomingGetPath, 'get');
    if (params) {
      rb.query('currencyType', params.currencyType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetUpcomingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetUpcomingGet$Plain(params?: {

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<DateTimeInvoiceDtoIGroupingListBaseResponse> {

    return this.apiInvoiceGetUpcomingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>) => r.body as DateTimeInvoiceDtoIGroupingListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceGetUpcomingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetUpcomingGet$Json$Response(params?: {

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiInvoiceGetUpcomingGetPath, 'get');
    if (params) {
      rb.query('currencyType', params.currencyType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceGetUpcomingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceGetUpcomingGet$Json(params?: {

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<DateTimeInvoiceDtoIGroupingListBaseResponse> {

    return this.apiInvoiceGetUpcomingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>) => r.body as DateTimeInvoiceDtoIGroupingListBaseResponse)
    );
  }

}
