/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { JobDtoBaseResponse } from '../models/job-dto-base-response';
import { JobListDto } from '../models/job-list-dto';
import { JobListDtoBaseResponse } from '../models/job-list-dto-base-response';
import { JobListDtoListBaseResponse } from '../models/job-list-dto-list-base-response';
import { JobListDtoPagedResponseBaseResponse } from '../models/job-list-dto-paged-response-base-response';
import { JobStatusDto } from '../models/job-status-dto';
import { JobStatusDtoBaseResponse } from '../models/job-status-dto-base-response';
import { JobStatusDtoListBaseResponse } from '../models/job-status-dto-list-base-response';
import { OrderDirection } from '../models/order-direction';
import { PaginationRequest } from '../models/pagination-request';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class JobService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiJobListJobsPost
   */
  static readonly ApiJobListJobsPostPath = '/api/Job/list-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobListJobsPost$Plain$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<JobListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobListJobsPost$Plain(params?: {
    body?: PaginationRequest
  }): Observable<JobListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoPagedResponseBaseResponse>) => r.body as JobListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobListJobsPost$Json$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<JobListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobListJobsPost$Json(params?: {
    body?: PaginationRequest
  }): Observable<JobListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoPagedResponseBaseResponse>) => r.body as JobListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobListJobsForCompanyGet
   */
  static readonly ApiJobListJobsForCompanyGetPath = '/api/Job/list-jobs-for-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsForCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Plain$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<JobListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsForCompanyGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsForCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Plain(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<JobListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsForCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoPagedResponseBaseResponse>) => r.body as JobListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsForCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Json$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<JobListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsForCompanyGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsForCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Json(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<JobListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsForCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoPagedResponseBaseResponse>) => r.body as JobListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobAllListJobsGet
   */
  static readonly ApiJobAllListJobsGetPath = '/api/Job/all-list-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAllListJobsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAllListJobsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAllListJobsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Plain(params?: {
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobAllListJobsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAllListJobsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAllListJobsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAllListJobsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Json(params?: {
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobAllListJobsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobGet
   */
  static readonly ApiJobJobGetPath = '/api/Job/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Plain(params?: {
    jobId?: number;
  }): Observable<JobDtoBaseResponse> {

    return this.apiJobJobGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobDtoBaseResponse>) => r.body as JobDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Json(params?: {
    jobId?: number;
  }): Observable<JobDtoBaseResponse> {

    return this.apiJobJobGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobDtoBaseResponse>) => r.body as JobDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobPut
   */
  static readonly ApiJobJobPutPath = '/api/Job/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Plain$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Plain(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Json$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Json(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobPost
   */
  static readonly ApiJobJobPostPath = '/api/Job/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Plain$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Plain(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Json$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Json(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobListItemGet
   */
  static readonly ApiJobJobListItemGetPath = '/api/Job/job-list-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobListItemGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobListItemGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobListItemGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Plain(params?: {
    jobId?: number;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobListItemGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobListItemGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobListItemGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobListItemGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Json(params?: {
    jobId?: number;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobListItemGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobToggleArchiveJobDelete
   */
  static readonly ApiJobToggleArchiveJobDeletePath = '/api/Job/toggle-archive-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleArchiveJobDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Plain$Response(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleArchiveJobDeletePath, 'delete');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleArchiveJobDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Plain(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleArchiveJobDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleArchiveJobDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Json$Response(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleArchiveJobDeletePath, 'delete');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleArchiveJobDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Json(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleArchiveJobDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobListJobStatusesGet
   */
  static readonly ApiJobListJobStatusesGetPath = '/api/Job/list-Job-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobStatusesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<JobStatusDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobStatusesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Plain(params?: {
  }): Observable<JobStatusDtoListBaseResponse> {

    return this.apiJobListJobStatusesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoListBaseResponse>) => r.body as JobStatusDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobStatusesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<JobStatusDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobStatusesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Json(params?: {
  }): Observable<JobStatusDtoListBaseResponse> {

    return this.apiJobListJobStatusesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoListBaseResponse>) => r.body as JobStatusDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobAddJobStatusPost
   */
  static readonly ApiJobAddJobStatusPostPath = '/api/Job/add-job-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAddJobStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Plain$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<JobStatusDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAddJobStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAddJobStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Plain(params?: {
    body?: JobStatusDto
  }): Observable<JobStatusDtoBaseResponse> {

    return this.apiJobAddJobStatusPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoBaseResponse>) => r.body as JobStatusDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAddJobStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Json$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<JobStatusDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAddJobStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAddJobStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Json(params?: {
    body?: JobStatusDto
  }): Observable<JobStatusDtoBaseResponse> {

    return this.apiJobAddJobStatusPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoBaseResponse>) => r.body as JobStatusDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobUpdateJobStatusPut
   */
  static readonly ApiJobUpdateJobStatusPutPath = '/api/Job/update-job-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUpdateJobStatusPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Plain$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUpdateJobStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUpdateJobStatusPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Plain(params?: {
    body?: JobStatusDto
  }): Observable<BooleanBaseResponse> {

    return this.apiJobUpdateJobStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUpdateJobStatusPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Json$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUpdateJobStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUpdateJobStatusPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Json(params?: {
    body?: JobStatusDto
  }): Observable<BooleanBaseResponse> {

    return this.apiJobUpdateJobStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobArchiveJobStatusDelete
   */
  static readonly ApiJobArchiveJobStatusDeletePath = '/api/Job/archive-job-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobArchiveJobStatusDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Plain$Response(params?: {
    statusId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobArchiveJobStatusDeletePath, 'delete');
    if (params) {
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobArchiveJobStatusDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Plain(params?: {
    statusId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobArchiveJobStatusDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobArchiveJobStatusDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Json$Response(params?: {
    statusId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobArchiveJobStatusDeletePath, 'delete');
    if (params) {
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobArchiveJobStatusDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Json(params?: {
    statusId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobArchiveJobStatusDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
