import { NgModule } from "@angular/core";
import { NoPreloading, PreloadAllModules, RouterModule, RouterOutlet, provideRouter, withInMemoryScrolling, withPreloading } from "@angular/router";
import {
  BrowserModule
} from "@angular/platform-browser";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";

// import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
  provideHttpClient,
} from "@angular/common/http";
// import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ApiModule } from "./api/api.module";
import { environment } from "environments/environment";
import { appRoutes } from "./app.routes";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { LuxonDateAdapter } from "@angular/material-luxon-adapter";
import { provideTransloco } from "./core/services/transloco/transloco.provider";
import { provideIcons } from "./core/services/icons/icons.provider";
import { provideFuse } from "@fuse";
import { mockApiServices } from "./mock-api";
import { SharedComponentsModule } from "./core/components/shared-components.module";
import { SharedModule } from "./core/shared.module";
import { TokenInterceptor } from "./api/services/cookie-jwt-auth/interceptors/token.interceptor";
import { ColorPickerModule } from "ngx-color-picker";
// AoT requires an exported function for factories
// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new TranslateHttpLoader(httpClient);
// }

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SharedComponentsModule,
    RouterOutlet,
    HttpClientModule,
    ColorPickerModule,
    ApiModule.forRoot({ rootUrl: environment.apiURL }),
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      preloadingStrategy: NoPreloading,
    }),
  ],
  declarations: [AppComponent],

  providers: [
    // { provide: ErrorHandler, useClass: ErrorHandlerService },
    // // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    // },
    // REQUIRED IF YOU USE JWT AUTHENTICATION
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },

    provideAnimations(),
    provideHttpClient(),
    // provideRouter(appRoutes,
    //     withPreloading(PreloadAllModules),
    //     withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
    // ),

    // Material Date Adapter
    {
        provide : DateAdapter,
        useClass: LuxonDateAdapter,
    },
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: 'D',
            },
            display: {
                dateInput         : 'DDD',
                monthYearLabel    : 'LLL yyyy',
                dateA11yLabel     : 'DD',
                monthYearA11yLabel: 'LLLL yyyy',
            },
        },
    },

    // Transloco Config
    provideTransloco(),

    // Fuse
    // provideAuth(),
    provideIcons(),
    provideFuse({
        mockApi: {
            delay   : 0,
            services: mockApiServices,
        },
        fuse   : {
            layout : 'classy',
            scheme : 'light',
            screens: {
                sm: '600px',
                md: '960px',
                lg: '1280px',
                xl: '1440px',
            },
            theme  : 'theme-default',
            themes : [
                {
                    id  : 'theme-default',
                    name: 'Default',
                },
                {
                    id  : 'theme-nexus',
                    name: 'Nexus',
                },
                {
                    id  : 'theme-brand',
                    name: 'Brand',
                },
                {
                    id  : 'theme-teal',
                    name: 'Teal',
                },
                {
                    id  : 'theme-rose',
                    name: 'Rose',
                },
                {
                    id  : 'theme-purple',
                    name: 'Purple',
                },
                {
                    id  : 'theme-amber',
                    name: 'Amber',
                },
            ],
        },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
